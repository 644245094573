// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {Rdirtcrtc: {hover: true, pressed: true}};

const cycleOrder = ["Rdirtcrtc"];

const variantClassNames = {Rdirtcrtc: "framer-v-1kbibo7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; link?: string; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Rdirtcrtc", title: vDaRAtWAr = "Buy Now", link: GQllaNkBQ, tap: kS6AO508V, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Rdirtcrtc", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapv3af6u = activeVariantCallback(async (...args) => {
if (kS6AO508V) {
const res = await kS6AO508V(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-imJ4C", classNames)} style={{display: "contents"}}>
<Link href={GQllaNkBQ} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-1kbibo7", className)} framer-1j4g6kk`} data-border data-framer-name={"Button"} data-highlight layoutDependency={layoutDependency} layoutId={"Rdirtcrtc"} onTap={onTapv3af6u} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgba(22, 22, 22, 0.15)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", background: "linear-gradient(180deg, rgba(200, 255, 127, 1) 0%, rgba(165, 229, 80, 1) 100%)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, boxShadow: "inset 0px 2px 8px 0px rgba(255, 255, 255, 0.20000000298023224), inset 0px -2px 8px 0px rgba(0, 0, 0, 0.10000000149011612)", ...style}} transition={transition} variants={{"Rdirtcrtc-hover": {background: "linear-gradient(180deg, #d6fca5 0%, #b9e67b 100%)"}, "Rdirtcrtc-pressed": {background: "linear-gradient(180deg, #e3fcc3 0%, #cfeea6 100%)"}}} {...addPropertyOverrides({"Rdirtcrtc-hover": {"data-framer-name": undefined}, "Rdirtcrtc-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "600", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Buy Now</motion.p></React.Fragment>} className={"framer-6hdk6q"} data-framer-name={"Buy Now"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"cjrbw9wv0"} style={{"--extracted-r6o4lv": "rgb(14, 19, 6)", "--framer-paragraph-spacing": "0px"}} text={vDaRAtWAr} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-imJ4C [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-imJ4C .framer-1j4g6kk { display: block; }", ".framer-imJ4C .framer-1kbibo7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 28px 16px 28px; position: relative; text-decoration: none; width: min-content; will-change: transform; }", ".framer-imJ4C .framer-6hdk6q { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-imJ4C .framer-v-1kbibo7 .framer-1kbibo7 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-imJ4C .framer-1kbibo7 { gap: 0px; } .framer-imJ4C .framer-1kbibo7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-imJ4C .framer-1kbibo7 > :first-child { margin-left: 0px; } .framer-imJ4C .framer-1kbibo7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 143
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"wfk0Z9LHG":{"layout":["auto","auto"]},"NBpR1qJua":{"layout":["auto","auto"]}}}
 * @framerVariables {"vDaRAtWAr":"title","GQllaNkBQ":"link","kS6AO508V":"tap"}
 */
const FramerYBqyyYDdJ: React.ComponentType<Props> = withCSS(Component, css, "framer-imJ4C") as typeof Component;
export default FramerYBqyyYDdJ;

FramerYBqyyYDdJ.displayName = "Button – Secondary";

FramerYBqyyYDdJ.defaultProps = {height: 60, width: 143};

addPropertyControls(FramerYBqyyYDdJ, {vDaRAtWAr: {defaultValue: "Buy Now", displayTextArea: false, title: "Title", type: ControlType.String}, GQllaNkBQ: {title: "Link", type: ControlType.Link}, kS6AO508V: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerYBqyyYDdJ, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/YBqyyYDdJ:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf", weight: "600"}])